import { httpRequests } from "../../../helpers/HttpRequests"

export function subscriptionsServices() {
    const { getRequest, httpErrors } = httpRequests()
    const errors = httpErrors

    function getCheckoutPortalTrialUrlService() {
        return getRequest('/web/stripe/get_trial_days')
    }

    function getCheckoutPortalUrlService() {
        return getRequest('/web/stripe/checkout_portal')
    }

    return { getCheckoutPortalUrlService, getCheckoutPortalTrialUrlService, errors }
}