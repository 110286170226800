<template>
    <a :href="checkoutPortalUrl" class="button button--bordered" :disabled="loader">
        <span class="button__loader" v-if="loader"></span>

        <img src="@/assets/icons/vuesax/linear/send-sqaure-2.svg" width="20" height="20" alt="" v-else>

        <span v-if="loader">Getting Subscription</span>
        <span v-else>Start Trialing Days</span>
    </a>
</template>

<script setup>
import { subscriptionsServices } from '../Services/SubscriptionsServices.js'
import { ref, onMounted } from 'vue'

const { getCheckoutPortalTrialUrlService } = subscriptionsServices()
const checkoutPortalUrl = ref(null)
const loader = ref(true)

onMounted(async () => {
    // * Get checkout trial portal url
    getCheckoutPortalTrialUrl()
})

async function getCheckoutPortalTrialUrl() {
    setTimeout(async () => {
        const response = await getCheckoutPortalTrialUrlService()
    
        if (response.hasErrors) return console.error(`Error getting trial checkout portal url: ${response.message}`)
    
        checkoutPortalUrl.value = response.data
        loader.value = false
    }, 4000)
}
</script>